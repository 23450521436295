import { ConstantRoutes } from "../../../core/constantRoutes";
import { useTranslation } from "react-i18next";
import { useNewsStore } from "../../../stores/newsStore";
import { useEffect } from "react";
import { useAuthStore } from "../../../stores/authStore";
import NewsItem from "./newsItem";

const News: React.FC = () => {
  const { t } = useTranslation();

  const { lang } = useAuthStore();
  const { news, fetchNews } = useNewsStore();

  useEffect(() => {
    fetchNews();
  }, []);

  const newsForCurrentLanguage = news.find((f) => f.lang === lang);

  return (
    <>
      <div className="flex flex-col z-10" id={ConstantRoutes.news}>
        <div className="max-w-screen-2xl w-full place-self-center text-center pb-10 md:pt-40 max-md:pt-10">
          <h1 className="text-4xl font-bold">{t('News')}</h1>
        </div>

        <div className="max-w-screen-2xl w-full place-self-center max-md:p-4">
          <div className="rounded-xl h-1/2 bg-content3 flex flex-col overflow-y-auto shadow-inner">
            {newsForCurrentLanguage ? (
              newsForCurrentLanguage.records.map((record, index) => (
                <NewsItem 
                key={index}
                title={record.header}
                text={record.description} 
                date={new Date(record.published)} 
              />
              ))
            ) : (
              <p>NO NEWS</p>
            )}                 
          </div>
        </div>        
      </div>
    </>
  );
};

export default News;
