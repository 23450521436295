import { useEffect, useState } from "react";
import { ConstantRoutes } from "../../../core/constantRoutes";
import { useFaqStore } from "../../../stores/faqStore";
import FaqItem from "./faqItem";
import { useTranslation } from "react-i18next";
import { useAuthStore } from "../../../stores/authStore";

const Faq: React.FC = () => {
  const { i18n } = useTranslation();

  const { lang } = useAuthStore();

  const { faq, fetchFaq } = useFaqStore();

  useEffect(() => {
    fetchFaq();
  }, []);

  const faqForCurrentLanguage = faq.find((f) => f.lang === lang);

  return (
    <>
      <div className="flex flex-col z-10" id={ConstantRoutes.faq}>
        <div className="max-w-screen-2xl w-full place-self-center text-center pb-10 md:pt-40 max-md:pt-10">
          <h1 className="text-4xl font-bold pb-6">FAQ</h1>

          <div className="max-md:p-4">
            {faqForCurrentLanguage ? (
              faqForCurrentLanguage.records.map((record, index) => (
                <FaqItem
                  key={index}
                  header={record.header}
                  fullText={record.description}
                />
              ))
            ) : (
              <p>NO FAQs</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
