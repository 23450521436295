import { useTranslation } from "react-i18next";
import { useAuthStore } from "../../stores/authStore";
import AuthServiceInstance from "../../api/authServices";
import { useModalAuthorizationStore } from "../../stores/modalAuthorizationStore";

const RegisterForm: React.FC = () => {
  const {
    email,
    password,
    repeatPassword,
    setEmail,
    setPassword,
    setRepeatPassword,
    message,
    setMessage,
    isSuccess,
    setIsSuccess
  } = useAuthStore();
  const { t } = useTranslation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setMessage('');
    e.preventDefault();

    const registrationResult = await AuthServiceInstance.register({
      email,
      password,
    });

    setEmail("");
    setPassword("");
    setRepeatPassword("");

    if (registrationResult) {
      setMessage(t('Registration success'));
      setIsSuccess(true);
    }
    else{
      setMessage(t('Registration error'))
      setIsSuccess(false);
    }
  };

  const isDisabled =
    email.trim() === "" ||
    password.trim() === "" ||
    repeatPassword.trim() === "" ||
    password !== repeatPassword ||
    password.length < 6 ||
    email.indexOf("@") < 0;

  return (
    <>
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder={t("Email")}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="block mb-4 w-full  outline-none px-2 py-1 bg-content3 rounded-md p-2"
      />
      <input
        type="password"
        placeholder={t("Password")}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="block mb-4 w-full  outline-none px-2 py-1 bg-content3  rounded-md p-2"
      />
      <input
        type="password"
        placeholder={t("Repeat password")}
        value={repeatPassword}
        onChange={(e) => setRepeatPassword(e.target.value)}
        className="block mb-4 w-full  outline-none px-2 py-1 bg-content3  rounded-md p-2"
      />
      <button
        type="submit"
        className={`bg-primary rounded-md px-4 py-2 ${
          isDisabled ? "opacity-10 cursor-not-allowed" : "opacity-100"
        } transition duration-300 ease-in-out`}
        disabled={isDisabled}
      >
        {t("Register")}
      </button>
    </form>
    {(message.length > 1) &&
        <h1 className={`p-2 text-lg ${isSuccess ? "text-success" : "text-danger"}`}>{message}</h1>
      }
    </>
  );
};

export default RegisterForm;
