export const ConstantRoutes = {
  main: '/',
  fileProcessing: '/cabinet/file-processing',
  orderHistory: '/cabinet/order-history',
  originalFile: '/cabinet/original-file',
  damosMappack: '/cabinet/damos-mappack',
  support: '/cabinet/support',
  supportTicket: '/cabinet/support/ticket/',

  register: 'register',
  topUpBalance: 'topUpBalance',

  externalDamos: 'https://dme-tech.store/product-category/damos-database/',
  externalOriginal: 'https://dme-tech.store/original-bmw-files/',
  youtube: 'https://www.youtube.com/channel/UClwXRkLkH2jf26vl4ysKeAA?view_as=subscriber',
  instagram: 'https://www.instagram.com/dme_chiptuning?igsh=MTc4ZTR0N2FhN2lsaA%3D%3D&utm_source=qr',

  ecuHelp: 'ecuhelp',
  tutorials: 'tutorials',
  pricing: 'pricing',
  news: 'news',
  faq: 'faq',

  adminUsers: '/admin-tech/users',
  adminOfflineService: '/admin-tech/offlineservice',
  adminEmailSettings: '/admin-tech/emailsettings',
  adminSupportCenter: '/admin-tech/supportcenter',
  adminWorkTime: '/admin-tech/worktime',
  adminAllowedSizes: '/admin-tech/allowedsizes',
  adminCompares: '/admin-tech/compares',
  adminSignatures: '/admin-tech/signatures',
  adminSwid: '/admin-tech/swid',
  adminGroups: '/admin-tech/groups',
  adminCapacityTable: '/admin-tech/capacitytable',
  adminFaq: '/admin-tech/faq',
  adminNews: '/admin-tech/news',
  adminOfflineSettings: '/admin-tech/offlinesettings'
}