import React, { useState } from "react";
import { InformationCircle } from "react-huge-icons/outline";

interface CheckboxProps {
  checkboxText: string;
  tooltipText: string;
  checked: boolean;
  onChange: (checked: boolean, checkboxText: string) => void;
  id: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  checkboxText,
  tooltipText,
  checked,
  onChange,
  id,
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  const handleCheckboxChange = () => {
    onChange(!checked, checkboxText);
  };

  return (
    <div
      className={`flex items-center justify-between py-1 px-2 m-1 lg:w-52 xl:w-80 max-md:w-full h-14 bg-secondary rounded-xl border
        ${
          checked ? "border-primary" : "border-secondary"
        } transition-all duration-300 relative`}
    >
      <div className="flex items-center">
        <input
          id={id}
          type="checkbox"
          value=""
          className="hidden"
          checked={checked}
          onChange={handleCheckboxChange}
        />
        <label htmlFor={id} className="flex items-center cursor-pointer">
          <div
            className={`w-6 h-6 flex items-center justify-center mr-2 border-2 rounded-md focus-within:border-primary
             ${
               checked ? "border-primary bg-primary" : "border-textsecondary"
             } transition-all duration-300`}
          >
            {checked && (
              <svg
                className="w-4 h-4 text-white fill-current"
                viewBox="0 0 24 24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4z" />
              </svg>
            )}
          </div>
          <span className="">{checkboxText}</span>
        </label>
      </div>
      {tooltipText.length > 0 && (
        <div
          className="group relative"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="cursor-pointer">
            <InformationCircle className="text-textsecondary w-5 h-5 hover:text-primary transition-all duration-300" />
          </div>
          <div
            className={`absolute left-1/2 transform -translate-x-1/2 bottom-full z-50 group-hover:scale-100 transition-all duration-300 scale-0`}
          >
            <div className="bg-content1 px-3 py-2 rounded-lg text-sm shadow-md min-w-40">
              {tooltipText}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Checkbox;
