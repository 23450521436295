import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import initializeI18n from './core/i18n';
import { BrowserRouter } from 'react-router-dom';

initializeI18n.changeLanguage('en');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>  
    <BrowserRouter>
      <main className='dark text-foreground bg-content3'>      
        <App />
      </main>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
