import { useEffect } from "react";
import { useModalRefillBalanceStore } from "../../stores/modalRefillBalanceStore";
import { useTranslation } from "react-i18next";

interface ModalProps {}

const ModalAttention: React.FC<ModalProps> = () => {
  const isOpen = useModalRefillBalanceStore(
    (state) => state.modalAttentionOpen
  );

  const {modalFirmwareProcessor} = useModalRefillBalanceStore();
  const { t } = useTranslation();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isOpen) {
        closeModal();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const closeModal = () => {
    useModalRefillBalanceStore.setState({ modalAttentionOpen: false });
  };

  const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    if (target.classList.contains("bg-black")) {
      closeModal();
    }
  };

  const handleClickSubmit = async () => {
    modalFirmwareProcessor();
    closeModal();
  };

  return (
    <div
      className="fixed top-0 left-0 z-50 w-full h-full flex justify-center items-center bg-black bg-opacity-60"
      onClick={handleClickOutside}
    >
      <div className="relative bg-content2 p-8 rounded-lg w-126 h-72 text-center flex flex-col justify-between animate-fade-in">
        <button
          className="absolute top-4 right-4 bg-transparent text-textsecondary text-3xl"
          onClick={() => closeModal()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <>
          <h1 className="text-textsecondary mt-5">
            <span className="text-danger">{t("Attention")}</span>{" "}
            {t("Attention firmware upload text")}
          </h1>

          <button
            className="text-center cursor-pointer rounded-lg bg-primary h-11 w-full hover:animate-pulse transition duration-300 ease-in-out mt-4"
            onClick={handleClickSubmit}
          >
            {t("Submit")}
          </button>
        </>
      </div>
    </div>
  );
};

export default ModalAttention;