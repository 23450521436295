import { useEffect, useState } from "react";
import { useModalRefillBalanceStore } from "../../stores/modalRefillBalanceStore";
import { useTranslation } from "react-i18next";
import AuthServiceInstance from "../../api/authServices";

interface ModalProps {}

const ModalForgotPassword: React.FC<ModalProps> = () => {
  const isOpen = useModalRefillBalanceStore(
    (state) => state.modalForgotPasswordOpen
  );
  const [email, setEmail] = useState<string>("");
  const [instructionsSent, setinstructionsSent] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isOpen) {
        closeModal();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const closeModal = () => {
    useModalRefillBalanceStore.setState({ modalForgotPasswordOpen: false });
    setEmail("");
    setinstructionsSent(false);
  };

  const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    if (target.classList.contains("bg-black")) {
      closeModal();
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await AuthServiceInstance.forgotPassword(email);

    setEmail("");
    setinstructionsSent(true);
  };

  const isDisabled = email.trim() === "" || email.indexOf("@") < 0;

  return (
    <div
      className="fixed top-0 left-0 z-50 w-full h-full flex justify-center items-center bg-black bg-opacity-60"
      onClick={handleClickOutside}
    >
      <div className="relative bg-content2 p-8 rounded-lg w-126 h-72 text-center flex flex-col justify-between animate-fade-in">
        <button
          className="absolute top-4 right-4 bg-transparent text-textsecondary text-3xl"
          onClick={() => closeModal()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <h2 className="text-2xl  mb-4">{t("Email")}</h2>
        <p className="text-textsecondary">
          {t(
            "If You forgot password write email here and you will receive instructions."
          )}
        </p>
        {instructionsSent && (
          <p className="text-success">
            {t("Instructions has been sent. Please check Your email address.")}
          </p>
        )}
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder={t("Email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="block mb-4 w-full  outline-none px-2 py-1 bg-content3 rounded-md p-2"
          />
          <button
            type="submit"
            className={`bg-primary rounded-md px-4 py-2 ${
              isDisabled ? "opacity-10 cursor-not-allowed" : "opacity-100"
            } transition duration-300 ease-in-out`}
            disabled={isDisabled}
          >
            {t("Send instructions")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ModalForgotPassword;
