import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

interface CircularProgressProps {
  progress: number;
}

const CircularProgress: React.FC<CircularProgressProps> = ({ progress }) => {
  progress = 100 - progress;
  const circumference = 2 * Math.PI * 22;
  const dashoffset = circumference * (1 - progress / 100);

  return (
    <svg className="w-14 h-14 rotate-90">
      <circle
        className="text-tablebg stroke-current"
        strokeWidth="8"
        fill="none"
        r="40%"
        cx="50%"
        cy="50%"
      />
      <circle
        className="text-primary stroke-current"
        strokeDasharray={circumference}
        strokeDashoffset={dashoffset}
        strokeWidth="8"
        strokeLinecap="round"
        fill="none"
        r="40%"
        cx="50%"
        cy="50%"
      />
    </svg>
  );
};

interface CountdownTimerProps {
  timeRemaining: number;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ timeRemaining }) => {
  const [remaining, setRemaining] = useState(timeRemaining);

  useEffect(() => {
    const timer = setInterval(() => {
      setRemaining((prevRemaining) => prevRemaining - 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [timeRemaining]);

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const remainingMinutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${hours.toString().padStart(2, "0")}:${remainingMinutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  return <div className="text-4xl font-semibold">{formatTime(remaining)}</div>;
};

interface CountdownComponentProps {
  openTime: number;
  closeTime: number;
}

const CircularCountdown: React.FC<CountdownComponentProps> = ({
  openTime,
  closeTime,
}) => {
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const totalTime = openTime - closeTime;
      const elapsedTime = Math.max(0, openTime - currentTime);
      const currentProgress = Math.min(100, (elapsedTime / totalTime) * 100);
      setProgress(currentProgress);
    }, 1000);

    return () => clearInterval(interval);
  }, [openTime, closeTime]);

  return (
    <>
        <div className="flex items-center">
          <div className="w-16 h-16 m-1 p-4">
            <CircularProgress progress={progress} />
          </div>
          <div className="ml-4 pt-4 w-40">
            <p className="text-textsecondary pb-2">{t('Until opens')}:</p>
            <CountdownTimer
              timeRemaining={(openTime - new Date().getTime()) / 1000}
            />
          </div>
        </div>    
    </>
  );
};

export default CircularCountdown;
