import "./../../App.css";
import Dropdown from "../dropdown/dropdown";
import LanguageSelector from "./languageSelector";
import { useTranslation } from "react-i18next";
import { useModalRefillBalanceStore } from "../../stores/modalRefillBalanceStore";
import AuthServiceInstance from "../../api/authServices";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../../stores/authStore";
import { Coin } from "react-huge-icons/outline";
import useBreadcrumbsStore, {
  BreadcrumbItem,
} from "../../stores/breadcrumbStore";
import { ConstantRoutes } from "../../core/constantRoutes";
import MainLogo from "../mainLogo";
import useLeftMenuStore from "../../stores/leftMenuStore";
import { MenuLineHorizontal } from "react-huge-icons/solid";

function TopMenuCabinet() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAuthenticated, authorizedEmail, balance } = useAuthStore();
  const breadcrumbs = useBreadcrumbsStore((state) => state.breadcrumbs);
  const { isOpen } = useLeftMenuStore();

  return (
    <>
      <div className="bg-content3 top-0 sticky z-10 drop-shadow shadow-content3 shadow-lg">
        <div className="lg:p-2 xl:p-6">
          <div className="flex rounded-xl bg-secondary h-16 items-center">
            <ol className="list-none px-6 inline-flex max-md:hidden">
              {breadcrumbs.map((breadcrumb: BreadcrumbItem, index: number) => (
                <li key={index} className="flex items-center">
                  <p
                    className="text-content1 hover:underline ml-1 cursor-pointer"
                    onClick={() => navigate(breadcrumb.path)}
                  >
                    {t(breadcrumb.label)}
                  </p>
                  <span className="text-content1 px-3">{">"}</span>
                </li>
              ))}
            </ol>

            <div className="flex-grow max-md:hidden"></div>

            <div className="border border-solid border-content1 h-full max-md:hidden" />

            <div className="lg:px-2 xl:px-6 max-md:hidden">
              <LanguageSelector />
            </div>

            <div className="md:hidden">
              <MainLogo mode="light" onclickUrl={ConstantRoutes.main} />
            </div>

            {isAuthenticated() && (
              <>
                <div className="border border-solid border-content1 h-full" />
                <div className="md:px-6 max-md:px-3 flex items-center">
                  <div className="md:pr-6 max-md:pr-3">
                    <p className="text-textsecondary">{t("Your balance")}</p>
                    <div className="flex flex-row items-center">
                      <p className="text-xl"> {balance} </p>
                      <Coin className="ml-2 w-5 h-5" />
                    </div>
                  </div>
                  <p
                    className="flex items-center justify-center border-primary 
                        border border-solid rounded-lg px-3 cursor-pointer hover:bg-primary transition duration-300 ease-in-out
                         h-10 w-25 max-md:hidden"
                    onClick={() =>
                      useModalRefillBalanceStore.setState({ modalOpen: true })
                    }
                  >
                    {t("Refill")}
                  </p>

                  <p
                    className="flex items-center justify-center border-primary 
                        border border-solid rounded-lg px-3 cursor-pointer hover:bg-primary transition duration-300 ease-in-out
                         h-10 w-10 md:hidden text-3xl"
                    onClick={() =>
                      useModalRefillBalanceStore.setState({ modalOpen: true })
                    }
                  >
                    +
                  </p>
                </div>

                <div className="border border-solid border-content1 h-full" />

                <div className="flex items-center lg:px-3 xl:px-6 max-md:hidden">
                  <p className="bg-content2 p-2 text-xl rounded-xl w-12 h-12 text-center">
                    N
                  </p>

                  <Dropdown
                    items={[
                      { id: "profile", label: t("Profile") },
                      { id: "quit", label: t("Exit") },
                    ]}
                    onSelect={function (item: {
                      id: string;
                      label: string;
                    }): void {
                      if (item.id === "quit") {
                        AuthServiceInstance.logout();
                        navigate(ConstantRoutes.main);
                      }
                    }}
                    caption={authorizedEmail ?? "ERROR"}
                  />
                </div>

                <div className="md:hidden">
                  {!isOpen && (
                    <MenuLineHorizontal
                      className="ml-1 
                          rounded-lg cursor-pointer hover:bg-primary transition duration-300 ease-in-out
                          h-10 w-10 md:hidden text-2xl"
                      onClick={() =>
                        useLeftMenuStore.setState({ isOpen: true })
                      }
                    />
                  )}

                  {isOpen && (
                    <div
                      className="ml-2 
                    rounded-lg cursor-pointer hover:bg-primary transition duration-300 ease-in-out
                     md:hidden text-3xl"
                      onClick={() =>
                        useLeftMenuStore.setState({ isOpen: false })
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-8 w-8"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default TopMenuCabinet;
