import { ReactNode } from "react";

export interface TableCellProps {
  children: ReactNode;
  additionalClassNames?: string;
}

export const TableCell: React.FC<TableCellProps> = ({ children }) => (
  <td className="lg:py-1 lg:px-1 xl:py-4 xl:px-4 md:text-xs lg:text-xs xl:text-base border border-tableline align-top">{children}</td>
);

export const TableHeader: React.FC<TableCellProps> = ({
  children,
  additionalClassNames,
}) => {
  const defaultClassNames =
    "lg:py-1 lg:px-1 xl:py-4 xl:px-4 md:text-xs lg:text-xs xl:text-base text-left text-textsecondary border border-tableline";
  const classNames = additionalClassNames
    ? `${defaultClassNames} ${additionalClassNames}`
    : defaultClassNames;

  return <th className={classNames}>{children}</th>;
};
