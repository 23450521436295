import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TableCell, TableHeader } from "../../table/tableCellHeader";
import { useParams } from "react-router-dom";
import useOfflineServiceStore from "../../../stores/admin/offlineServiceStore";
import OfflineService from "../../../api/admin/offlineService";

const AdminOfflineServiceRecordInfo: React.FC = () => {
  const { t } = useTranslation();
  const { order, fetchOrder } = useOfflineServiceStore();

  const { file } = useParams<{ file: string }>();

  const offlineService = new OfflineService();

  useEffect(() => {
    fetchOrder(Number(file));
  }, []);

  function downloadFile(id: any): void {
    offlineService.downloadFile(id);
  }

  return (
    <>
      <div className="flex-1 flex flex-col">
        <div className="rounded-xl overflow-hidden border border-tableline max-md:hidden">
          <table className="w-full">
            <thead className="bg-tablebg2">
              <tr>
                <TableHeader additionalClassNames="w-20">№</TableHeader>
                <TableHeader additionalClassNames="w-20">Email</TableHeader>
                <TableHeader additionalClassNames="w-20">Series</TableHeader>
                <TableHeader additionalClassNames="w-52">Ecu</TableHeader>
                <TableHeader additionalClassNames="w-20">Engine</TableHeader>
                <TableHeader additionalClassNames="w-52">Software</TableHeader>
                <TableHeader additionalClassNames="w-52">Options</TableHeader>
                <TableHeader additionalClassNames="w-32">Uploaded</TableHeader>
                <TableHeader additionalClassNames="w-32">Status</TableHeader>
                <TableHeader additionalClassNames="w-40">Action</TableHeader>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-tablebg" key="1">
                {order !== undefined && (
                  <>
                    <TableCell>№{order.id}</TableCell>
                    <TableCell>{order.email}</TableCell>
                    <TableCell>{order.series}</TableCell>
                    <TableCell>{order.ecu}</TableCell>
                    <TableCell>{order.engine}</TableCell>
                    <TableCell>{order.software}</TableCell>
                    <TableCell>
                      <div className="flex flex-wrap">
                        {order.options !== undefined &&
                          order.options.map((option: any, subindex: number) => (
                            <p className="py-1 px-2 m-1 text-primary bg-primary bg-opacity-30 rounded-xl">
                              {option}
                            </p>
                          ))}
                      </div>
                    </TableCell>
                    <TableCell>
                      {new Date(order.uploaded).toLocaleDateString("ru-RU")}
                    </TableCell>
                    <TableCell>
                      {order.status !== 4 ? (
                        <p className="text-success">{t("Processed")}</p>
                      ) : (
                        <p className="text-careful">{t("In processing")}</p>
                      )}
                    </TableCell>
                    <TableCell>
                      <div className="flex-1">
                        <button
                          className="flex items-center justify-center border-primary 
                            border border-solid rounded-lg px-3 cursor-pointer bg-primary h-10 w-38 my-2"
                          onClick={() => downloadFile(order.id)}
                        >
                          {t("Download")}
                        </button>
                      </div>
                    </TableCell>
                  </>
                )}
              </tr>
            </tbody>
          </table>
        </div>

        <div className="md:hidden">
          <div
            className="rounded-xl overflow-hidden border border-tableline bg-tablebg my-4 p-4
                  flex flex-wrap"
          >
            {order !== undefined && (
              <>
                <div className="p-2 w-1/2">
                  <p className="text-textsecondary">№</p>
                  <p>№ {order.id}</p>
                </div>
                <div className="p-2 w-1/2">
                  <p className="text-textsecondary">Email</p>
                  <p>{order.email}</p>
                </div>

                <div className="p-2 w-1/2">
                  <p className="text-textsecondary">Series</p>
                  <p>{order.series}</p>
                </div>
                <div className="p-2 w-1/2">
                  <p className="text-textsecondary">ECU</p>
                  <p>{order.ecu}</p>
                </div>
                <div className="p-2 w-1/2">
                  <p className="text-textsecondary">Engine</p>
                  <p>{order.engine}</p>
                </div>
                <div className="p-2 w-full">
                  <p className="text-textsecondary">Software</p>
                  <p>{order.software}</p>
                </div>
                <div className="p-2 w-full">
                  <p className="text-textsecondary">Options</p>
                  <div className="flex flex-wrap">
                    {order.options !== undefined &&
                      order.options.map((option: any, subindex: number) => (
                        <p className="py-1 px-2 m-1 text-primary bg-primary bg-opacity-30 rounded-xl">
                          {option}
                        </p>
                      ))}
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <p className="text-textsecondary">Uploaded</p>
                  <p> {new Date(order.uploaded).toLocaleDateString("ru-RU")}</p>
                </div>
                <div className="p-2 w-1/2">
                  <p className="text-textsecondary">Status</p>
                  <p>
                    {" "}
                    {order.status !== 4 ? (
                      <p className="text-success">{t("Processed")}</p>
                    ) : (
                      <p className="text-careful">{t("In processing")}</p>
                    )}
                  </p>
                </div>
                <div className="flex w-full justify-center border-t border-tableline pt-4">
                  <button
                    className="flex items-center justify-center border-primary 
                            border border-solid rounded-lg px-3 cursor-pointer bg-primary h-10 w-11/12 my-2 mx-2"
                    onClick={() => downloadFile(order.id)}
                  >
                    {t("Download")}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminOfflineServiceRecordInfo;
