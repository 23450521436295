import React, { useEffect } from "react";
import {
  File,
  Undo,
  FileUpload,
  ArchiveDone,
  Help,
  Home,
} from "react-huge-icons/outline";
import { Whatsapp, Mail } from "react-huge-icons/solid";
import { useNavigate, useLocation } from "react-router-dom";
import "./../../App.css";
import MainLogo from "../mainLogo";
import MenuItem from "./menuItem";
import { useTranslation } from "react-i18next";
import { useAuthStore } from "../../stores/authStore";
import { ConstantRoutes } from "../../core/constantRoutes";
import { ConstantInfo } from "../../core/constantInfo";
import { useWorktimeStore } from "../../stores/worktimeStore";
import useLeftMenuStore from "../../stores/leftMenuStore";
import Dropdown from "../dropdown/dropdown";
import AuthServiceInstance from "../../api/authServices";

function LeftMenu() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleItemClick = (route: string) => {
    setIsOpen(false);
    navigate(route);
  };

  const { t } = useTranslation();

  const { isAuthenticated, authorizedEmail } = useAuthStore();

  const { worktimes, fetchWorktimes } = useWorktimeStore();
  const { isOpen, setIsOpen } = useLeftMenuStore();

  useEffect(() => {
    fetchWorktimes();
  }, []);

  return (
    <div
      className={`flex flex-col h-screen sticky top-0 ${
        isOpen ? "translate-x-0 " : "max-md:-translate-x-full"
      } 
    transition-transform duration-300 ease-in-out md:translate-x-0`}
    >
      <div
        className={`flex-grow bg-content2 rounded-xl overflow-hidden min-h-screen 
        ${
          isOpen ? "lg:w-65 max-lg:w-96" : "max-md:w-0"
        } transition-all duration-300 ease-in-out`}
      >
        <div className="max-md:hidden">
          <MainLogo mode="light" onclickUrl={ConstantRoutes.main} />
        </div>

        <div className="border border-solid border-divider" />

        {isAuthenticated() && (
          <div className="p-4">
            <MenuItem
              text={t("File processing")}
              icon={File}
              onClick={() => handleItemClick(ConstantRoutes.fileProcessing)}
              isSelected={location.pathname.startsWith(
                ConstantRoutes.fileProcessing
              )}
            />
            <MenuItem
              text={t("Order history")}
              icon={Undo}
              onClick={() => handleItemClick(ConstantRoutes.orderHistory)}
              isSelected={location.pathname.startsWith(
                ConstantRoutes.orderHistory
              )}
            />
            <MenuItem
              text={t("Original file")}
              icon={FileUpload}
              onClick={() => handleItemClick(ConstantRoutes.originalFile)}
              isSelected={location.pathname.startsWith(
                ConstantRoutes.originalFile
              )}
            />
            <MenuItem
              text={t("Damos&Mappack")}
              icon={ArchiveDone}
              onClick={() => handleItemClick(ConstantRoutes.damosMappack)}
              isSelected={location.pathname.startsWith(
                ConstantRoutes.damosMappack
              )}
            />
            <MenuItem
              text={t("Support")}
              icon={Help}
              onClick={() => handleItemClick(ConstantRoutes.support)}
              isSelected={location.pathname.startsWith(ConstantRoutes.support)}
            />
            <MenuItem
              text={t("Home")}
              icon={Home}
              onClick={() => handleItemClick(ConstantRoutes.main)}
              isSelected={location.pathname === ConstantRoutes.main}
            />
          </div>
        )}

        <div className="sticky top-[100vh]">
          <div className="border border-solid border-divider" />

          <div className="flex p-4 items-center">
            <p className="pr-3">{t("Techsupport")}</p>
            <>
              {worktimes != null &&
                new Date().getTime() < worktimes?.open.getTime() &&
                new Date().getTime() > worktimes?.close.getTime() && (
                  <div className="flex items-center py-2 px-3 rounded-md bg-red-800">
                    <span className="text-red-400">{t("Close")}</span>
                  </div>
                )}
              {(worktimes == null ||
                (worktimes != null &&
                  (new Date().getTime() > worktimes?.open.getTime() ||
                    new Date().getTime() < worktimes?.close.getTime()))) && (
                <div className="flex items-center py-2 px-3 rounded-md bg-green-800">
                  <span className="text-green-400">{t("Open")}</span>
                </div>
              )}
            </>
          </div>

          <div className="border border-solid border-divider" />

          <div className="flex items-center p-4 h-20">
            <a
              href={ConstantInfo.phone}
              className="bg-secondary flex items-center justify-center p-2 rounded-full cursor-pointer hover:bg-primary transition duration-300 ease-in-out w-roundedcontact h-roundedcontact mr-4"
            >
              <Whatsapp className="w-6 h-6" />
            </a>
            <a
              href={ConstantInfo.email + "Other question"}
              className="bg-secondary flex items-center justify-center p-2 rounded-full cursor-pointer hover:bg-primary transition duration-300 ease-in-out w-roundedcontact h-roundedcontact"
            >
              <Mail className="w-6 h-6" />
            </a>
          </div>

          <div className="md:hidden">
            {isAuthenticated() && (
              <div className="p-4">
                <div className="flex items-center bg-content3 p-4 rounded-xl">
                  <p className="bg-content2 p-2 text-xl rounded-xl w-12 h-12 text-center">
                    N
                  </p>

                  <Dropdown
                    items={[
                      { id: "profile", label: t("Profile") },
                      { id: "quit", label: t("Exit") },
                    ]}
                    onSelect={function (item: {
                      id: string;
                      label: string;
                    }): void {
                      if (item.id === "quit") {
                        AuthServiceInstance.logout();
                        navigate(ConstantRoutes.main);
                      }
                    }}
                    caption={authorizedEmail ?? "ERROR"}
                    reverseDirection={true}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftMenu;
