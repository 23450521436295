import React, { useEffect } from "react";
import {
  User,
} from "react-huge-icons/outline";
import { Whatsapp, Mail } from "react-huge-icons/solid";
import { useNavigate, useLocation } from "react-router-dom";
import "./../../../App.css";
import MainLogo from "../../mainLogo";
import { useTranslation } from "react-i18next";
import { useAuthStore } from "../../../stores/authStore";
import { ConstantRoutes } from "../../../core/constantRoutes";
import { useWorktimeStore } from "../../../stores/worktimeStore";
import useLeftMenuStore from "../../../stores/leftMenuStore";
import Dropdown from "../../dropdown/dropdown";
import AuthServiceInstance from "../../../api/authServices";
import MenuItem from "../../leftMenu/menuItem";

function AdminLeftMenu() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleItemClick = (route: string) => {
    setIsOpen(false);
    navigate(route);
  };

  const { t } = useTranslation();

  const { isAuthenticated, authorizedEmail } = useAuthStore();

  const { worktimes, fetchWorktimes } = useWorktimeStore();
  const { isOpen, setIsOpen } = useLeftMenuStore();

  useEffect(() => {
    fetchWorktimes();
  }, []);

  return (
    <div
      className={`flex flex-col h-screen sticky top-0 ${
        isOpen ? "translate-x-0 " : "max-md:-translate-x-full"
      } 
    transition-transform duration-300 ease-in-out md:translate-x-0`}
    >
      <div
        className={`flex-grow bg-content2 rounded-xl overflow-hidden min-h-screen 
        ${
          isOpen ? "md:w-65 max-md:w-96" : "max-md:w-0"
        } transition-all duration-300 ease-in-out`}
      >
        <div className="max-md:hidden">
          <MainLogo mode="light" onclickUrl={ConstantRoutes.main} />
        </div>

        <div className="border border-solid border-divider" />

        {isAuthenticated() && (
          <div className="p-4">
            <MenuItem
              text={t("Users")}
              icon={User}
              onClick={() => handleItemClick(ConstantRoutes.adminUsers)}
              isSelected={location.pathname.startsWith(
                ConstantRoutes.adminUsers
              )}
            />
            <MenuItem
              text={t("OfflineService")}
              icon={User}
              onClick={() => handleItemClick(ConstantRoutes.adminOfflineService)}
              isSelected={location.pathname.startsWith(
                ConstantRoutes.adminOfflineService
              )}
            />
            <MenuItem
              text={t("EmailSettings")}
              icon={User}
              onClick={() => handleItemClick(ConstantRoutes.adminEmailSettings)}
              isSelected={location.pathname.startsWith(
                ConstantRoutes.adminEmailSettings
              )}
            />
             <MenuItem
              text={t("Support Center")}
              icon={User}
              onClick={() => handleItemClick(ConstantRoutes.adminSupportCenter)}
              isSelected={location.pathname.startsWith(
                ConstantRoutes.adminSupportCenter
              )}
            />
             <MenuItem
              text={t("Work Time")}
              icon={User}
              onClick={() => handleItemClick(ConstantRoutes.adminWorkTime)}
              isSelected={location.pathname.startsWith(
                ConstantRoutes.adminWorkTime
              )}
            />

            <hr />

            <MenuItem
              text={t("Allowed Sizes")}
              icon={User}
              onClick={() => handleItemClick(ConstantRoutes.adminAllowedSizes)}
              isSelected={location.pathname.startsWith(
                ConstantRoutes.adminAllowedSizes
              )}
            />
            <MenuItem
              text={t("Compares")}
              icon={User}
              onClick={() => handleItemClick(ConstantRoutes.adminCompares)}
              isSelected={location.pathname.startsWith(
                ConstantRoutes.adminCompares
              )}
            />
            <MenuItem
              text={t("Signatures")}
              icon={User}
              onClick={() => handleItemClick(ConstantRoutes.adminSignatures)}
              isSelected={location.pathname.startsWith(
                ConstantRoutes.adminSignatures
              )}
            />
            <MenuItem
              text={t("Swid")}
              icon={User}
              onClick={() => handleItemClick(ConstantRoutes.adminSwid)}
              isSelected={location.pathname.startsWith(
                ConstantRoutes.adminSwid
              )}
            />
            <MenuItem
              text={t("Groups")}
              icon={User}
              onClick={() => handleItemClick(ConstantRoutes.adminGroups)}
              isSelected={location.pathname.startsWith(
                ConstantRoutes.adminGroups
              )}
            />
            <MenuItem
              text={t("Capacity table")}
              icon={User}
              onClick={() => handleItemClick(ConstantRoutes.adminCapacityTable)}
              isSelected={location.pathname.startsWith(
                ConstantRoutes.adminCapacityTable
              )}
            />
            <MenuItem
              text={t("FAQ")}
              icon={User}
              onClick={() => handleItemClick(ConstantRoutes.adminFaq)}
              isSelected={location.pathname.startsWith(
                ConstantRoutes.adminFaq
              )}
            />
            <MenuItem
              text={t("News")}
              icon={User}
              onClick={() => handleItemClick(ConstantRoutes.adminNews)}
              isSelected={location.pathname.startsWith(
                ConstantRoutes.adminNews
              )}
            />
            <MenuItem
              text={t("Offline Settings")}
              icon={User}
              onClick={() => handleItemClick(ConstantRoutes.adminOfflineSettings)}
              isSelected={location.pathname.startsWith(
                ConstantRoutes.adminOfflineSettings
              )}
            />

          </div>
        )}

        <div className="sticky top-[80vh]">
          <div className="md:hidden">
            {isAuthenticated() && (
              <div className="p-4">
                <div className="flex items-center bg-content3 p-4 rounded-xl">
                  <p className="bg-content2 p-2 text-xl rounded-xl w-12 h-12 text-center">
                    N
                  </p>

                  <Dropdown
                    items={[
                      { id: "profile", label: t("Profile") },
                      { id: "quit", label: t("Exit") },
                    ]}
                    onSelect={function (item: {
                      id: string;
                      label: string;
                    }): void {
                      if (item.id === "quit") {
                        AuthServiceInstance.logout();
                        navigate(ConstantRoutes.main);
                      }
                    }}
                    caption={authorizedEmail ?? "ERROR"}
                    reverseDirection={true}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminLeftMenu;
