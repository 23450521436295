import React, { useEffect } from "react";
import { Attachment } from "react-huge-icons/outline";
import { useTranslation } from "react-i18next";
import { ConstantRoutes } from "../../../core/constantRoutes";
import { TableCell, TableHeader } from "../../table/tableCellHeader";
import { useNavigate, useParams } from "react-router-dom";
import useOfflineServiceStore from "../../../stores/admin/offlineServiceStore";
import useBreadcrumbsStore from "../../../stores/breadcrumbStore";
import OfflineService from "../../../api/admin/offlineService";
import AdminOfflineServiceRecordInfo from "./adminOfflineServiceRecordInfo";
import AdminOfflineServiceRecordUploadFile from "./adminOfflineServiceRecordUploadFile";
import AdminOfflineServiceRecordChat from "./adminOfflineServiceRecordChat";

const AdminOfflineServiceRecord: React.FC = () => {
  const { t } = useTranslation();
  const { order, fetchOrder,  } = useOfflineServiceStore();
  const { addBreadcrumb, clearBreadcrumbs } = useBreadcrumbsStore();
  const { file } = useParams<{ file: string }>();
  const navigate = useNavigate();
  const offlineService = new OfflineService();

  useEffect(() => {
    clearBreadcrumbs();
    addBreadcrumb({
      label: "Offline service",
      path: ConstantRoutes.adminOfflineService,
    });
    addBreadcrumb({
      label: file ?? "",
      path: ConstantRoutes.adminOfflineService + "/work/" + file,
    });

    fetchOrder(Number(file));
  }, []);

  return (
    <>
      <AdminOfflineServiceRecordInfo />

      <AdminOfflineServiceRecordUploadFile />

      <div className="pt-8"> 
        <AdminOfflineServiceRecordChat />
      </div>

    </>
  );
};

export default AdminOfflineServiceRecord;
