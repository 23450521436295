import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "../../dropdown/dropdown";
import { useTranslation } from "react-i18next";
import { useAuthStore } from "../../../stores/authStore";
import LanguageSelector from "../../topMenuCabinet/languageSelector";
import AuthServiceInstance from "../../../api/authServices";
import MainLogo from "../../mainLogo";
import { useModalAuthorizationStore } from "../../../stores/modalAuthorizationStore";
import { ConstantRoutes } from "../../../core/constantRoutes";
import useLeftMenuStore from "../../../stores/leftMenuStore";
import { MenuLineHorizontal } from "react-huge-icons/outline";

const HomeHeader: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAuthenticated, authorizedEmail, balance } = useAuthStore();
  const { isOpen } = useLeftMenuStore();

  return (
    <>
      <div className="bg-black top-0 sticky z-10 drop-shadow shadow-black shadow-lg h-24 flex flex-col">
        <div className="max-w-screen-2xl w-full place-self-center">
          <div className="flex items-center justify-between">
            <MainLogo mode="dark" onclickUrl={ConstantRoutes.main} />

            <div className="flex flex-row justify-around items-center w-1/2 max-md:hidden">
              <div>
                <a
                  href={`#${ConstantRoutes.tutorials}`}
                  className="text-textsecondary transition-all duration-300 hover:text-white text-xl"
                >
                  {t("Tutorials")}
                </a>
              </div>
              <div>
                <a
                  href={`#${ConstantRoutes.pricing}`}
                  className="text-textsecondary transition-all duration-300 hover:text-white text-xl"
                >
                  {t("Pricing")}
                </a>
              </div>
              <div>
                <a
                  href={`#${ConstantRoutes.news}`}
                  className="text-textsecondary transition-all duration-300 hover:text-white text-xl"
                >
                  {t("News")}
                </a>
              </div>
              <div>
                <a
                  href={`#${ConstantRoutes.faq}`}
                  className="text-textsecondary transition-all duration-300 hover:text-white text-xl"
                >
                  {t("FAQ")}
                </a>
              </div>
              <div>
                <a
                  href={ConstantRoutes.externalDamos}
                  className="text-textsecondary transition-all duration-300 hover:text-white text-xl"
                >
                  {t("Damos")}
                </a>
              </div>
              <div>
                <a
                  href={ConstantRoutes.externalOriginal}
                  className="text-textsecondary transition-all duration-300 hover:text-white text-xl"
                >
                  {t("Original Files")}
                </a>
              </div>
            </div>

            <div className="px-6">
              <LanguageSelector />
            </div>

            <div className="md:hidden w-10 mr-10">
                  {!isOpen && (
                    <MenuLineHorizontal
                      className="ml-1 
                          rounded-lg cursor-pointer hover:bg-primary transition duration-300 ease-in-out
                          h-10 w-10 md:hidden text-2xl"
                      onClick={() =>
                        useLeftMenuStore.setState({ isOpen: true })
                      }
                    />
                  )}

                  {isOpen && (
                    <div
                      className="ml-2 
                    rounded-lg cursor-pointer hover:bg-primary transition duration-300 ease-in-out
                     md:hidden text-3xl"
                      onClick={() =>
                        useLeftMenuStore.setState({ isOpen: false })
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-8 w-8"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </div>
                  )}
                </div>

            <div className="max-md:hidden">
              {isAuthenticated() && (
                <>
                  <div className="flex items-center px-6">
                    <p className="bg-content2 p-2 text-xl rounded-xl w-12 h-12 text-center">
                      N
                    </p>

                    <p
                      className="pl-2 cursor-pointer"
                      onClick={() => navigate(ConstantRoutes.fileProcessing)}
                    >
                      {authorizedEmail}
                    </p>

                    <Dropdown
                      items={[
                        { id: "profile", label: t("Profile") },
                        { id: "quit", label: t("Exit") },
                      ]}
                      onSelect={function (item: {
                        id: string;
                        label: string;
                      }): void {
                        if (item.id === "quit") {
                          AuthServiceInstance.logout();
                          navigate("/");
                        }
                      }}
                      caption={""}
                    />
                  </div>
                </>
              )}

              {!isAuthenticated() && (
                <>
                  <button
                    className="border border-primary rounded-md w-24 h-10 items-center 
                        justify-center bg-primary hover:animate-pulse transition duration-300 ease-in-out mr-4"
                    onClick={() =>
                      useModalAuthorizationStore.setState({
                        modalOpen: true,
                        activeTab: "login",
                      })
                    }
                  >
                    {t("Sign In")}
                  </button>
                  <button
                    className="rounded-md w-24 h-10 items-center 
                        justify-center hover:bg-primary transition duration-300 ease-in-out"
                    onClick={() =>
                      useModalAuthorizationStore.setState({
                        modalOpen: true,
                        activeTab: "register",
                      })
                    }
                  >
                    {t("Sign Up")}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeHeader;
