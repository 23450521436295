import React from "react";
import { ConstantInfo } from "../../core/constantInfo";
import { Mail, Whatsapp } from "react-huge-icons/outline";
import CircularCountdown from "../circularCountdown/circularCountdown";
import Checkbox from "../checkbox/checkbox";
import { useTranslation } from "react-i18next";
import { useWorktimeStore } from "../../stores/worktimeStore";
import { useUploadStore } from "../../stores/uploadFirmwareStore";
import { Patch } from "../../models/firmware";

interface TgFlashSlaveProps {
  patches: Patch[];
}

const TgFlashSlaveInfo: React.FC<TgFlashSlaveProps> = ({ patches }) => {
  const { t } = useTranslation();
  const { worktimes } = useWorktimeStore();
  const {
    comment,
    setComment,
    checkedTgFlashPatches,
    setCheckedTgFlashPatches,
    setMaxPatchPrice,
    firmwareInfo
  } = useUploadStore();

  const handleCheckboxPatchChange = (
    isChecked: boolean,
    checkboxText: string
  ) => {
    let updatedPatches: Patch[];
    const patch = firmwareInfo?.patches.find(
      (x) => x.name === checkboxText && x.patchMode === 2
    )!;
    if (isChecked) {
      updatedPatches = [...checkedTgFlashPatches, patch];
    } else {
      updatedPatches = checkedTgFlashPatches.filter(
        (p) => p.name !== patch.name
      );
    }

    setCheckedTgFlashPatches(updatedPatches);

    const maxPrice =
      updatedPatches.length > 0
        ? Math.max(...updatedPatches.map((patch) => patch.price))
        : null;

    setMaxPatchPrice(maxPrice);
  };

  return (
    <>
      <div className="rounded-xl bg-content2 mb-8 ">
        <div className="">
          <div className="flex flex-row">
            <div className="p-8">
              <h1 className="text-2xl font-medium pb-5">
                {t("TGFlash Slave")}
              </h1>
              <p className="text-textsecondary">
                {t(
                  "Your file is encrypted; the server cannot process such files automatically. But you can place an order manually, select the options you need and write to the administrator."
                )}
              </p>
            </div>

            {worktimes != null &&
              new Date().getTime() < worktimes?.open.getTime() &&
              new Date().getTime() > worktimes?.close.getTime() && (
                <div className="w-64 h-24 bg-secondary rounded-tr-2xl rounded-bl-2xl">
                  <CircularCountdown
                    openTime={worktimes.open.getTime()}
                    closeTime={worktimes.close.getTime()}
                  />
                </div>
              )}
          </div>

          <div className="flex flex-row">
            <div className="flex flex-row items-center pl-8 pr-8">
              <Whatsapp className="w-8 h-8 my-3 pr-2" />
              <p className="pr-1">(Whatsapp)</p>
              <a href={ConstantInfo.phone}>{ConstantInfo.phoneView}</a>
            </div>
            <div className="flex flex-row items-center">
              <Mail className="w-8 h-8 my-3 pr-2" />
              <a className="pr-2" href={ConstantInfo.email + "Offline service"}>
                {ConstantInfo.emailView}
              </a>
            </div>
          </div>
        </div>

        <div className="p-8 flex flex-wrap">
          {patches.map((patch, index) => (
            <Checkbox
              key={index + "offline"}
              id={index + "offline"}
              checkboxText={patch.name}
              tooltipText={`${patch.name} Tip`}
              checked={checkedTgFlashPatches.some((p) => p.name === patch.name)}
              onChange={(isChecked, checkboxText) =>
                handleCheckboxPatchChange(isChecked, checkboxText)
              }
            />
          ))}
        </div>

        <div className="p-8">
          <p className="pb-5">{t("Your comments about the order")}</p>

          <textarea
            className="h-40 rounded-2xl bg-content3 w-full mb-5 px-5 py-3 resize-none"
            placeholder={t("Write message")}
            value={comment}
            onChange={(event) => setComment(event.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default TgFlashSlaveInfo;
